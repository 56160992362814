@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  outline: none;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.icons, .material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga'; }

html {
  font-size: 62.5% !important;
  touch-action: manipulation; }

body {
  font-size: 1.4rem;
  font-family: "Montserrat", sans-serif;
  background: #FFFFFF; }

.container {
  position: fixed;
  height: calc(100% - (7rem + 9rem));
  width: 100%;
  padding-bottom: 7rem;
  padding-top: 9rem;
  top: 0;
  line-height: 1.4;
  color: #4C4D5D;
  overflow-y: auto; }
  @media (max-height: 320px) {
    .container {
      height: calc(100% - (9rem));
      padding-bottom: 0;
      min-height: 100%; } }
  .container--full {
    height: 100%;
    padding-top: 0;
    padding-bottom: 0; }
    @media (max-height: 320px) {
      .container--full {
        height: 100%; } }
    @media (min-width: 1200px), (min-width: 768px) and (max-width: 1199px) {
      .container--full.container--desktop-sidebar {
        display: block; } }
  @media (min-width: 1200px), (min-width: 768px) and (max-width: 1199px) {
    .container--desktop-sidebar {
      display: flex;
      height: 100%;
      padding-top: 0;
      overflow-y: hidden; } }

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500; }

strong {
  font-weight: 500 !important; }

h1 {
  font-size: 4.48rem; }

h2 {
  font-size: 3.08rem; }

h3 {
  font-size: 1.96rem; }

h4 {
  font-size: 1.68rem; }

h5 {
  font-size: 1.4rem; }

h6 {
  font-size: 1.26rem; }

blockquote {
  padding-left: 2rem;
  margin-bottom: 2rem; }

.spinner {
  width: 70px;
  position: absolute;
  text-align: center; }
  .spinner > div {
    width: 10px;
    height: 10px;
    background-color: #ffffff;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both; }
  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s; }
  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s; }
  .spinner--secondary > div {
    background-color: #FEC44F; }
  .spinner--light > div {
    background-color: #FFFFFF; }

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.material-icons {
  max-width: 2.5rem;
  min-width: unset;
  width: auto; }

.button {
  background: transparent;
  border-radius: 0.5rem;
  height: 5.5rem;
  box-shadow: none;
  border: none;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 1.8rem;
  letter-spacing: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-decoration: none;
  -webkit-transition: opacity .2s ease, background-color .2s ease;
  transition: opacity .2s ease, background-color .2s ease;
  outline: none;
  line-height: 1;
  cursor: pointer; }
  .button__content {
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    margin: 0 auto; }
  .button--rounded {
    border-radius: 100px;
    padding-left: 1.33333rem;
    padding-right: 1.33333rem; }
  .button--small {
    height: 3rem;
    font-size: 1.26rem; }
    .button--small .material-icons {
      font-size: 1.4rem;
      max-width: 1.4rem; }
  .button--extra-small {
    height: 3rem;
    font-size: 1.4rem; }
    .button--extra-small .material-icons {
      font-size: 1.4rem;
      max-width: 1.4rem; }
  .button .material-icons {
    margin-right: 1rem;
    font-size: 1.8rem;
    max-width: 1.8rem; }
  .button--fluid, .button--submit, .button--button {
    width: 100%; }
  .button--floating {
    padding-left: 2rem;
    padding-right: 2rem;
    position: fixed;
    bottom: 9rem;
    border-radius: 5rem;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    height: 4rem;
    font-size: 1.4rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 10rem; }
  .button--list {
    height: auto;
    display: block;
    width: 100%;
    text-align: left;
    height: auto;
    height: 3rem;
    font-size: 1.6rem;
    padding: 0; }
  .button--inline {
    height: auto; }
  .button--disabled {
    opacity: .3; }
  .button .spinner {
    margin: auto;
    left: 0;
    right: 0; }
  .button--circle {
    width: 6rem;
    height: 6rem;
    border-radius: 100%; }
    .button--circle .material-icons, .button--circle svg {
      margin-right: 0; }
  .button--circle-small {
    width: 3rem;
    height: 3rem;
    border-radius: 100%; }
    .button--circle-small .material-icons, .button--circle-small svg {
      margin-right: 0; }
  .button:active {
    background: #ececec; }
  .button--light {
    color: #93949F;
    background: #FFFFFF; }
  .button--grey {
    color: #93949F;
    background: #ececec; }
  .button--primary {
    color: #FFFFFF;
    background: #19A87B; }
    .button--primary:active {
      background: #127c5a; }
  .button--primary-dark {
    color: #FFFFFF;
    background: #116E56; }
    .button--primary-dark:active {
      background: #0a4233; }
  .button--secondary {
    color: #FFFFFF;
    background: #FEC44F; }
    .button--secondary:active {
      background: #feb31c; }
  .button--dark {
    color: #FFFFFF;
    background: #4C4D5D; }
    .button--dark:active {
      background: #353641; }
  .button--danger {
    color: #FFFFFF;
    background: #FF5E5E; }
    .button--danger:active {
      background: #ff2b2b; }
  .button--transparent {
    background: transparent;
    color: #4C4D5D;
    padding: 0; }
    .button--transparent:active {
      background: transparent; }
  .button--transparent-primary {
    background: transparent;
    color: #19A87B; }
    .button--transparent-primary:active {
      background: #ececec; }
  .button--transparent-danger {
    background: transparent;
    color: #FF5E5E; }
    .button--transparent-danger:active {
      background: #ececec; }
    .button--transparent-danger .spinner > .bounce1, .button--transparent-danger .bounce2, .button--transparent-danger .bounce3 {
      background-color: #FF5E5E; }
  .button:disabled {
    opacity: .3;
    cursor: not-allowed; }

a.button {
  color: #4C4D5D;
  outline: none; }

.input__label {
  display: block;
  width: 100%;
  min-height: 1.5rem;
  color: #4C4D5D;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  font-weight: 400; }
  .input__label .input__label__required {
    margin-left: 1rem;
    color: #FF5E5E; }

.input__wrapper {
  position: relative;
  display: flex; }
  .input__wrapper__icon {
    display: none; }
  .input__wrapper__input, .input__wrapper__file {
    width: 100%;
    height: 5rem;
    border-radius: 0.5rem;
    box-sizing: border-box;
    border: 2px solid #ececec;
    background: #FFFFFF;
    padding: 2rem;
    font-size: 1.4rem;
    outline: none;
    -webkit-transition: border 0.2s ease;
    transition: border 0.2s ease; }
    .input__wrapper__input:disabled, .input__wrapper__file:disabled {
      background-color: #e6e6e6;
      border-color: #e6e6e6; }
    .input__wrapper__input:focus, .input__wrapper__file:focus {
      border-color: #19A87B; }
    .input__wrapper__input:-webkit-autofill, .input__wrapper__input:-webkit-autofill:hover, .input__wrapper__input:-webkit-autofill:focus, .input__wrapper__file:-webkit-autofill, .input__wrapper__file:-webkit-autofill:hover, .input__wrapper__file:-webkit-autofill:focus {
      background-color: #FFFFFF !important;
      box-shadow: 0 0 1000rem inset #FFFFFF; }
  .input__wrapper__file {
    padding: 1rem 2rem;
    visibility: hidden;
    position: absolute;
    text-indent: -99999px; }
  .input__wrapper__take-photo {
    position: relative;
    width: 100%; }
    .input__wrapper__take-photo img {
      max-width: 100%;
      border-radius: 0.5rem; }
  .input__wrapper textarea {
    height: unset;
    min-height: 5rem;
    font-family: inherit;
    font-size: inherit;
    padding: 1rem 2rem;
    resize: vertical; }
  .input__wrapper__actions {
    display: flex;
    height: 100%;
    align-items: center;
    position: absolute;
    padding: 0 1rem;
    right: 0; }
  .input__wrapper__checkbox {
    opacity: 0;
    text-indent: -99999rem;
    position: absolute; }
    .input__wrapper__checkbox:checked + .input__wrapper__checkbox-label:before {
      content: "check";
      font-family: "Material Icons";
      background-color: #19A87B;
      border-color: #ececec;
      -webkit-transition: background-color 0.2s ease;
      transition: background-color 0.2s ease; }
    .input__wrapper__checkbox:disabled + .input__wrapper__checkbox-label:before {
      background-color: #93949F; }
  .input__wrapper__radio {
    opacity: 0;
    text-indent: -99999rem;
    position: absolute; }
    .input__wrapper__radio:checked + .input__wrapper__radio-label {
      position: relative; }
      .input__wrapper__radio:checked + .input__wrapper__radio-label:before {
        content: "";
        font-family: "Material Icons";
        background-color: #FFFFFF;
        border-color: #19A87B;
        -webkit-transition: background-color 0.2s ease;
        transition: background-color 0.2s ease;
        width: 2.5rem;
        height: 2.5rem; }
      .input__wrapper__radio:checked + .input__wrapper__radio-label:after {
        left: 0.6rem;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        content: "";
        width: 1.25rem;
        height: 1.25rem;
        background: #19A87B;
        position: absolute;
        border-radius: 100px; }
    .input__wrapper__radio:disabled + .input__wrapper__radio-label:before, .input__wrapper__radio:disabled + .input__wrapper__radio-label:after {
      opacity: 0.5; }
  .input__wrapper__radio-label {
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 500;
    z-index: 5;
    padding-left: 3.33333rem;
    min-height: 2.5rem; }
    .input__wrapper__radio-label:before {
      content: "";
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.66667rem;
      margin-right: 1rem;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 100px;
      border: 2px solid #ececec;
      color: #FFFFFF;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto auto;
      left: 0; }
  .input__wrapper__select {
    width: 100%;
    height: 5rem;
    border-radius: 0.5rem;
    box-sizing: border-box;
    border: 2px solid #ececec;
    background: #FFFFFF;
    padding: 0 1rem;
    font-size: 1.4rem;
    outline: none;
    -webkit-transition: border 0.2s ease;
    transition: border 0.2s ease; }
  .input__wrapper__multi-select {
    width: 100%; }
    .input__wrapper__multi-select__control {
      min-height: 3rem !important;
      border: 2px solid #19A87B !important;
      box-sizing: content-box;
      padding: 0.5rem 1rem; }
      .input__wrapper__multi-select__control--is-focused {
        border-color: #19A87B !important;
        box-shadow: none !important;
        border-width: 2px !important; }
    .input__wrapper__multi-select__menu {
      margin: 0 !important;
      border: none !important;
      box-shadow: none !important;
      border: 2px solid #ececec !important;
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1) !important;
      box-sizing: border-box;
      border-radius: 4px !important;
      overflow: hidden;
      z-index: 10 !important; }
    .input__wrapper__multi-select__menu-list {
      padding-top: 0 !important;
      padding-bottom: 0 !important; }
    .input__wrapper__multi-select__value-container {
      padding: 0.5rem 0 !important; }
      .input__wrapper__multi-select__value-container--is-multi {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        height: auto !important; }
    .input__wrapper__multi-select__indicators {
      align-self: flex-start !important;
      max-height: 3rem; }
    .input__wrapper__multi-select__indicator-separator {
      display: none; }
    .input__wrapper__multi-select__option {
      padding: 1.33333rem 1rem !important;
      color: #4C4D5D !important;
      cursor: pointer !important;
      font-size: 1.4rem !important; }
      .input__wrapper__multi-select__option:hover:not(.select__option--is-selected) {
        background: #DDF2EB !important; }
      .input__wrapper__multi-select__option--is-focused {
        color: #FFFFFF !important;
        background: #19A87B !important; }
      .input__wrapper__multi-select__option--is-selected {
        color: #FFFFFF !important;
        background: #19A87B !important; }
    .input__wrapper__multi-select__multi-value {
      display: flex;
      align-items: center;
      height: 2.5rem !important;
      border-radius: 0.5rem !important;
      background: #ececec !important;
      color: #93949F !important; }
      .input__wrapper__multi-select__multi-value__label {
        font-size: 1.4rem !important;
        padding: 0 1rem !important;
        color: #4C4D5D !important;
        font-weight: 500; }
  .input__wrapper__checkbox-label {
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 500;
    z-index: 5; }
    .input__wrapper__checkbox-label:before {
      content: "";
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.66667rem;
      margin-right: 1rem;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 0.5rem;
      border: 2px solid #ececec;
      color: #FFFFFF;
      box-sizing: border-box; }
  .input__wrapper__attachment {
    width: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    box-sizing: border-box;
    border: 2px solid #ececec;
    background: #fff;
    font-size: 1.4rem;
    outline: none;
    -webkit-transition: border 0.2s ease;
    transition: border 0.2s ease; }

.input--with-icon .input__wrapper__icon {
  display: flex;
  height: 100%;
  align-items: center;
  position: absolute;
  padding: 0 1rem;
  left: 0; }
  .input--with-icon .input__wrapper__icon--secondary {
    color: #FEC44F; }

.input--with-icon .input__wrapper__input {
  padding-left: 4rem; }

.input--with-icon--secondary .input__wrapper__icon i {
  color: #FEC44F; }

.input--with-icon--danger .input__wrapper__icon i {
  color: #FF5E5E; }

.input__error {
  font-size: 1.12rem;
  color: #FF5E5E;
  padding-left: 1rem; }

.DateRangePicker_picker {
  z-index: 104; }
  .DateRangePicker_picker .CalendarDay__selected_start,
  .DateRangePicker_picker .CalendarDay__selected_end {
    background: #19A87B !important;
    color: #FFFFFF !important;
    border-color: #19A87B !important; }
  .DateRangePicker_picker .CalendarDay__selected_span {
    background: #DDF2EB;
    color: #19A87B;
    border-color: #19A87B; }
  .DateRangePicker_picker .DateRangePicker_closeButton {
    left: 0; }
  .DateRangePicker_picker .DayPicker_transitionContainer .CalendarMonthGrid .CalendarDay {
    vertical-align: middle; }
  .DateRangePicker_picker .DayPickerNavigation {
    display: flex;
    height: 5rem; }
    .DateRangePicker_picker .DayPickerNavigation .DayPickerNavigation_button {
      box-shadow: none;
      background: #FFFFFF;
      height: 5rem;
      padding: 0;
      align-items: center;
      justify-content: center;
      display: flex;
      border-color: #ececec;
      bottom: 0;
      box-sizing: border-box; }
      .DateRangePicker_picker .DayPickerNavigation .DayPickerNavigation_button svg {
        height: 2.5rem;
        fill: #4C4D5D; }

.DateRangePicker {
  width: 100%; }
  .DateRangePicker .DateRangePickerInput {
    width: 100%;
    height: 5rem;
    border-radius: 0.5rem;
    box-sizing: border-box;
    border: 2px solid #19A87B;
    background: #FFFFFF;
    padding: 0 2rem;
    outline: none;
    -webkit-transition: border 0.2s ease;
    transition: border 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: stretch; }
    .DateRangePicker .DateRangePickerInput .DateRangePickerInput_arrow {
      padding: 0 2rem; }
    .DateRangePicker .DateRangePickerInput .DateInput {
      border: none;
      background: none;
      height: 5rem;
      width: unset; }
      .DateRangePicker .DateRangePickerInput .DateInput .DateInput_input {
        padding: 0;
        height: 5rem;
        line-height: 1;
        border: none;
        background: none;
        font-size: 1.4rem;
        font-weight: 500; }

.navigation {
  display: flex;
  position: fixed;
  bottom: 0;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  text-align: center;
  height: 7rem;
  background: #FFFFFF;
  box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.05);
  z-index: 101; }
  @media (max-height: 320px) {
    .navigation {
      position: static; } }
  @media (min-width: 768px) and (max-width: 1199px), (min-width: 1200px) {
    .navigation--sidebar {
      bottom: unset;
      left: 0;
      height: 100%;
      top: 0;
      min-height: 50rem;
      flex-direction: column;
      width: 30rem;
      justify-content: flex-start;
      position: static;
      -webkit-transition: width 500ms ease-in-out;
      transition: width 500ms ease-in-out; }
      .navigation--sidebar .navigation-item__label, .navigation--sidebar .navigation-bar__header__desc {
        visibility: visible;
        -webkit-transition: opacity 250ms ease-out 500ms;
        transition: opacity 250ms ease-out 500ms; }
      .navigation--sidebar + div {
        width: 100%;
        overflow-y: auto; }
      .navigation--sidebar.navigation--is-collapse {
        width: 7rem; }
        .navigation--sidebar.navigation--is-collapse .navigation-item__label, .navigation--sidebar.navigation--is-collapse .navigation-bar__header__desc {
          -webkit-transition: opacity 250ms ease-in;
          transition: opacity 250ms ease-in;
          opacity: 0; } }

.navigation-item {
  text-decoration: none;
  color: #797979;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  flex: 1 1;
  overflow: hidden;
  cursor: pointer; }
  .navigation-item--is-collapse-button {
    margin-top: auto;
    display: none; }
  @media (min-width: 1200px), (min-width: 768px) and (max-width: 1199px) {
    .navigation-item--sidebar {
      max-width: calc(100% - (2rem * 2)) !important;
      height: auto;
      padding: 0 2rem;
      flex-direction: row;
      justify-content: flex-start;
      max-height: 7rem;
      position: static; }
      .navigation-item--sidebar.navigation-item--is-collapse-button {
        display: flex; }
      .navigation-item--sidebar .navigation-item__icon {
        flex: 0 0 3rem;
        margin-right: 2rem;
        margin-bottom: unset; }
      .navigation-item--sidebar .navigation-item__label {
        font-weight: 500;
        font-size: 1.4rem; } }
  .navigation-item__icon {
    margin-bottom: .25rem;
    height: 2.5rem;
    align-items: center;
    justify-content: center;
    display: flex;
    color: #BEBFC5; }
    .navigation-item__icon i {
      font-size: 2.8rem; }
    .navigation-item__icon svg {
      max-height: 2rem;
      width: auto;
      fill: #BEBFC5; }
  .navigation-item__label {
    font-size: 1.2rem;
    font-weight: 500;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis; }
    .navigation-item__label span {
      max-width: 100%;
      line-height: 1;
      white-space: nowrap; }
  .navigation-item__counter {
    position: absolute;
    margin-top: -2.5rem;
    background: #FF5E5E;
    height: 1.5rem;
    display: inline-flex;
    align-items: center;
    width: auto;
    padding: 0 .75rem;
    left: 50%;
    top: 50%;
    justify-content: center;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 1;
    border-radius: 2.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-size: 1.26rem;
    line-height: 1; }
  .navigation-item:active {
    background: #f2f2f2; }
  .navigation-item.active {
    color: #19A87B; }
    .navigation-item.active svg * {
      fill: #19A87B; }
    .navigation-item.active .navigation-item__icon {
      color: #19A87B; }
    .navigation-item.active.navigation-item--with-line {
      position: relative; }
      .navigation-item.active.navigation-item--with-line:after {
        display: block;
        content: '';
        width: 4rem;
        background: #19A87B;
        height: .8rem;
        position: absolute;
        bottom: -.4rem;
        border-radius: .8rem;
        margin: 0 auto;
        left: 0;
        right: 0; }
      @media (min-width: 1200px), (min-width: 768px) and (max-width: 1199px) {
        .navigation-item.active.navigation-item--with-line.navigation-item--sidebar:after {
          bottom: unset;
          left: -.4rem;
          right: unset;
          width: .8rem;
          height: 4rem; } }

.modal-overlay {
  background-color: rgba(76, 77, 93, 0.5) !important;
  z-index: 103;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .modal-overlay--transparent {
    background: rgba(0, 0, 0, 0.7) !important; }

.ReactModalPortal {
  overscroll-behavior-y: contain;
  overflow-y: auto; }

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out;
  overflow: hidden; }

.ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModal__Overlay--before-close {
  opacity: 0; }

.modal {
  overflow: hidden;
  position: absolute;
  z-index: 104;
  padding: 0;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 0.5rem;
  border: none;
  width: calc(100% - 6rem);
  max-width: 50rem;
  height: calc(100% - 9rem);
  max-height: 20rem;
  background: #FFFFFF;
  height: auto; }
  .modal .button--button-close-card {
    position: absolute;
    top: -5rem;
    left: 1rem;
    color: #FFFFFF;
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem; }
    .modal .button--button-close-card i {
      margin-right: 0;
      font-size: 2.4rem; }
  .modal .button--button-close-full {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 2rem;
    padding-bottom: 0; }
  .modal--el {
    width: calc(100% - 6rem);
    max-width: 50rem;
    height: calc(100% - 9rem);
    max-height: 50rem; }
    .modal--el .modal__body {
      height: calc(100% - 4.5rem); }
  .modal--transparent {
    background: transparent !important; }
  .modal--card {
    width: 100%;
    bottom: 0;
    height: calc(100% - (9rem - 1.5rem));
    max-height: none;
    max-width: none;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    border-radius: 2rem 2rem 0 0;
    overflow: visible; }
  .modal--full {
    width: 100%;
    height: 100%;
    max-height: none;
    max-width: none;
    -webkit-transform: unset;
            transform: unset;
    border-radius: 0;
    overflow: auto;
    position: relative;
    top: 0;
    left: 0; }
  .modal__header {
    background: #FFFFFF;
    height: 5rem;
    display: flex;
    align-items: center; }
    .modal__header__text {
      font-weight: 500;
      align-items: center;
      height: 2rem;
      padding: 2rem;
      font-size: 1.4rem;
      width: calc(100% - 6.5rem);
      line-height: 1.2; }
    .modal__header__icon {
      display: inline-block; }
      .modal__header__icon i {
        vertical-align: middle;
        color: #93949F; }
  .modal__body {
    display: block;
    height: calc(100% - 12rem);
    overflow-y: auto; }
    .modal__body__content {
      padding: 2rem;
      line-height: 1.4; }
      .modal__body__content a {
        color: #19A87B; }
  .modal__action {
    display: flex;
    align-content: center;
    justify-content: center; }
    .modal__action button {
      min-width: 50%;
      font-size: 1.4rem;
      font-weight: 700; }
      .modal__action button.button--modal-close {
        color: #FF5E5E; }


.alert {
  display: flex;
  bottom: 0;
  height: auto;
  background: #4C4D5D;
  color: #FFFFFF;
  overflow: auto;
  width: calc(100% - (2rem * 4));
  margin: 0 auto;
  align-items: flex-start;
  padding: 1rem 2rem;
  line-height: 1.8;
  font-size: 1.4rem;
  position: fixed;
  z-index: 104;
  font-weight: 500;
  left: 0;
  right: 0;
  bottom: 2rem;
  border-radius: 0.5rem;
  align-items: center;
  visibility: hidden;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  -webkit-transition: -webkit-transform 150ms;
  transition: -webkit-transform 150ms;
  transition: transform 150ms;
  transition: transform 150ms, -webkit-transform 150ms;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); }
  .alert--signed {
    bottom: 9rem; }
  .alert--showed {
    visibility: visible;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  .alert .button {
    margin-left: auto;
    color: #FFFFFF; }
    .alert .button i {
      padding-right: 0;
      margin-right: 0; }
  .alert--warning {
    background: #FEC44F;
    color: #4C4D5D; }
    .alert--warning .button {
      color: #4C4D5D; }
  .alert--error {
    background: #FF5E5E;
    color: #FFFFFF; }
  .alert--success {
    color: #19A87B;
    background: #DDF2EB; }
    .alert--success .button {
      color: #19A87B; }

.change-password {
  position: relative;
  height: 100%;
  overflow: hidden;
  box-sizing: content-box; }
  .change-password form {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden; }
  .change-password__title {
    padding-left: 2rem;
    padding-right: 2rem;
    height: 5rem;
    align-items: center;
    display: flex; }
    .change-password__title h4 {
      overflow: hidden;
      text-overflow: ellipsis;
      box-sizing: content-box;
      line-height: 1;
      white-space: nowrap; }
  .change-password__form {
    padding: 0 2rem;
    height: calc(100% - (5rem + 5rem + 2rem + 2rem));
    overflow-y: auto; }
    .change-password__form p {
      margin-bottom: 2rem; }
    .change-password__form .input {
      margin-bottom: 2rem; }
    .change-password__form .input__wrapper__select {
      border-color: #19A87B; }
    .change-password__form__radio {
      display: flex; }
      .change-password__form__radio .input {
        margin-right: 2rem; }
  .change-password__action {
    height: 7rem;
    position: absolute;
    bottom: 0;
    width: calc(100% - (2rem * 2));
    padding: 0 2rem 2rem 2rem;
    box-sizing: content-box;
    padding-bottom: 2rem;
    display: flex;
    align-items: flex-end; }

.wrapper {
  background-color: #FFFFFF; }
  .wrapper > .spinner {
    position: absolute;
    left: 0;
    right: 0;
    margin: 50% auto 0 auto; }
    .wrapper > .spinner > div {
      background: #FEC44F; }

