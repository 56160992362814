@import '../../../config/theme';

.navigation{
  display: flex;
  position: fixed;
  bottom: 0;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  text-align: center;
  height: $nav-height;
  background: $nav-bg;
  box-shadow: 0 -1px 10px rgba(0, 0, 0, .05);
  z-index: $z-navbar;

  @media (max-height: 320px){
    position: static;
  }

  @media #{$tablet}, #{$desktop}{
    &--sidebar{
      bottom: unset;
      left: 0;
      height: 100%;
      top: 0;
      min-height: 50rem;
      flex-direction: column;
      width: $nav-width;
      justify-content: flex-start;
      position: static;
      transition: width 500ms ease-in-out;

      .navigation-item__label, .navigation-bar__header__desc{
        visibility: visible;
        transition: opacity 250ms ease-out 500ms;
      }

      + div{
        width: 100%;
        overflow-y: auto;
      }

      &.navigation--is-collapse{
        width: 3rem + ($el-pad * 2);

        .navigation-item__label, .navigation-bar__header__desc{
          transition: opacity 250ms ease-in;
          opacity: 0;
        }
      }
    }
  }
}