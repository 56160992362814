@import '../../../config/theme';

.navigation-item{
  text-decoration: none;
  color: $nav-color;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  flex: 1 1 0;
  overflow: hidden;
  cursor: pointer;

  &--is-collapse-button{
    margin-top: auto;
    display: none;
  }

  &--sidebar{
    @media #{$desktop}, #{$tablet}{
      max-width: calc(100% - (#{$el-pad} * 2)) !important;
      height: auto;
      padding: 0 $el-pad;
      flex-direction: row;
      justify-content: flex-start;
      max-height: $el-height + $el-pad;
      position: static;

      &.navigation-item--is-collapse-button{
        display: flex;
      }
  
      .navigation-item__icon{
        flex: 0 0 3rem;
        margin-right: $el-mar;
        margin-bottom: unset;
      }
  
      .navigation-item__label{
        font-weight: $weight-semi;
        font-size: $h5;
      }
    }
  }

  &__icon{
    margin-bottom: .25rem;
    height: 2.5rem;
    align-items: center;
    justify-content: center;
    display: flex;
    color: $nav-color-icon;
    
    i {
      font-size: $nav-icon;
    }

    svg{
      max-height: 2rem;
      width: auto;
      fill: $nav-color-icon;
    }
  }

  &__label{
    font-size: $nav-label;
    font-weight: $nav-label-weight;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    span{
      max-width: 100%;
      line-height: 1;
      white-space: nowrap;
    }
  }

  &__counter{
    position: absolute;
    margin-top: -2.5rem;
    background: $color-danger;
    height: 1.5rem;
    display: inline-flex;
    align-items: center;
    width: auto;
    padding: 0 .75rem;
    left: 50%;
    top: 50%;
    justify-content: center;
    font-weight: $weight-semi;
    color: $color-light;
    line-height: 1;
    border-radius: 2.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    font-size: $h6;
    line-height: 1;
  }

  &:active{
    background: darken($nav-bg, 5%)
  }

  // active
  &.active{
    color: $nav-color-active;

    svg *{
      fill: $nav-color-active;
    }

    .navigation-item__icon{
      color: $nav-color-active;
    }

    &.navigation-item--with-line{
      position: relative;

      &:after{
        display: block;
        content: '';
        width: 4rem;
        background: $nav-color-active;
        height: .8rem;
        position: absolute;
        bottom: -.4rem;
        border-radius: .8rem;
        margin: 0 auto;
        left: 0;
        right: 0;
      }
      
      @media #{$desktop}, #{$tablet}{
        &.navigation-item--sidebar{
          &:after{
            bottom: unset;
            left: -.4rem;
            right: unset;
            width: .8rem;
            height: 4rem;
          }
        }
      }
    }
  }
}