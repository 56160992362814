@import '../../config/theme';

.alert{
  display: flex;
  bottom: 0;
  height: auto;
  background: $color-dark;
  color: $color-light;
  overflow: auto;
  width: calc(100% - (#{$el-pad} * 4));
  margin: 0 auto;
  align-items: flex-start;
  padding: $el-pad/2 $el-pad;
  line-height: 1.8;
  font-size: 1.4rem;
  position: fixed;
  z-index: $z-alert;
  font-weight: $weight-semi;
  left: 0;
  right: 0;
  bottom: $el-pad;
  border-radius: $el-rad;
  align-items: center;
  visibility: hidden;
  transform: translateY(50%);
  transition: transform 150ms;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);

  &--signed{
    bottom: $nav-height + $el-pad;
  }

  &--showed{
    visibility: visible;
    transform: translateY(0);
  }
  
  .button{
    margin-left: auto;
    color: $color-light;
    i{
      padding-right: 0;
      margin-right: 0;
    }
  }

  &--warning{
    background: $color-secondary;
    color: $color-dark;

    .button{
      color: $color-dark;
    }
  }

  &--error{
    background: $color-danger;
    color: $color-light;
  }

  &--success{
    color: $color-primary;
    background: $color-primary-light;

    .button{
      color: $color-primary;
    }
  }
}
