
// Default variable wrapper
$font-base          : 1.4rem !default;
$font-header        : sans-serif !default;
$font-body          : sans-serif !default;
$bg                 : #FFFFFF !default;
$nav-height         : 0 !default;
$header-height      : 0 !default;
$color-dark         : #353535 !default;
$h1                 : $font-base * 3.5 !default;
$h2                 : $font-base * 2.5 !default;
$h3                 : $font-base * 1.8 !default;
$h4                 : $font-base * 1.6 !default;
$h5                 : $font-base * 1.2 !default;
$h6                 : $font-base !default;
$el-pad             : 2rem !default;
$weight-semi        : 500 !default;

@import '../../../config/theme';
@import './reset';
@import './icons';

// Base font-size for scalling
html{
  font-size: 62.5% !important;
  touch-action: manipulation;
}

body{
  font-size: $font-base;
  font-family: $font-body;
  background: $bg;
}

// Wrapper
.container{
  position: fixed;
  height: calc(100% - (#{$nav-height} + #{$header-height})); 
  width: 100%; 
  padding-bottom: $nav-height;
  padding-top: $header-height;
  top: 0;
  line-height: 1.4;
  color: $color-dark;
  overflow-y: auto;

  @media (max-height: 320px){
    height: calc(100% - (#{$header-height})); 
    padding-bottom: 0;
    min-height: 100%;
  }
  
  &--full{
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
    

    @media (max-height: 320px){
      height: 100%; 
    }

    &.container--desktop-sidebar{
      @media #{$desktop}, #{$tablet}{
        display: block;
      }
    }
  }

  &--desktop-sidebar{
    @media #{$desktop}, #{$tablet}{
      display: flex;
      height: 100%;
      padding-top: 0;
      overflow-y: hidden;
    }
  }
}

h1, h2, h3, h4, h5, h6{
  font-family: $font-header;
  font-weight: $weight-semi;
}

strong{
  font-weight: $weight-semi !important;
}

h1{
  font-size: $h1;
}
h2{
  font-size: $h2;
}
h3{
  font-size: $h3;
}
h4{
  font-size: $h4;
}
h5{
  font-size: $h5;
}
h6{
  font-size: $h6;
}

blockquote{
  padding-left: $el-pad;
  margin-bottom: $el-pad;
}