@import '../../config/theme';

.change-password{
  position: relative;
  height: 100%;
  overflow: hidden;
  box-sizing: content-box;

  form{
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  &__title{  
    padding-left: $el-pad;
    padding-right: $el-pad;
    height: $el-height;
    align-items: center;
    display: flex;

    h4{
      overflow: hidden;
      text-overflow: ellipsis;
      box-sizing: content-box; 
      line-height: 1;
      white-space: nowrap;
    }
  }

  &__form{
    padding: 0 $el-pad;
    height: calc(100% - (#{$el-height} + #{$el-height} + #{$el-pad} + #{$el-pad}));
    overflow-y: auto;

    p{
      margin-bottom: $el-mar;
    }

    .input{
      margin-bottom: $el-mar;
    }

    .input__wrapper__select{
      border-color: $color-primary;
    }

    &__radio{
      display: flex;

      .input{
        margin-right: $el-mar;
      }
    }
  }

  &__action{
    height: $el-height + $el-pad;
    position: absolute;
    bottom: 0;
    width: calc(100% - (#{$el-pad} * 2));
    padding: 0 $el-pad $el-pad $el-pad;
    box-sizing: content-box;
    padding-bottom: $el-pad;
    display: flex;
    align-items: flex-end;
  }
}