@import '../../../config/theme';

.button{
  background: transparent;
  border-radius: $el-rad;
  height: 5.5rem;
  box-shadow: none;
  border: none;
  box-sizing: border-box;
  font-weight: $weight-semi;
  font-size: 1.8rem;
  letter-spacing: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-decoration: none;
  transition: opacity .2s ease, background-color .2s ease;
  outline: none;
  line-height: 1;
  cursor: pointer;

  &__content{
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  &--rounded{
    border-radius: 100px;
    padding-left: $el-pad/1.5;
    padding-right: $el-pad/1.5;
  }

  &--small{
    height: 3rem;
    font-size: $h6;

    .material-icons{
      font-size: 1.4rem;
      max-width: 1.4rem;
    }
  }

  &--extra-small{
    height: 3rem;
    font-size: 1.4rem;

    .material-icons{
      font-size: 1.4rem;
      max-width: 1.4rem;
    }
  }

  .material-icons{
    margin-right: $el-pad/2;
    font-size: 1.8rem;
    max-width: 1.8rem;
  }
 
  // type
  &--fluid, &--submit, &--button{
    width: 100%;
  }

  &--floating{
    padding-left: $el-pad;
    padding-right: $el-pad;
    position: fixed;
    bottom: $nav-height + $el-pad;
    border-radius: $el-height;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    height: 4rem;
    font-size: $h5;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 10rem;
  }

  &--list{
    height: auto;
    display: block;
    width: 100%;
    text-align: left;
    height: auto;
    height: 3rem;
    font-size: 1.6rem;
    padding: 0;
  }

  &--inline{
    height: auto;
  }

  &--disabled{
    opacity: .3;
  }

  .spinner{
    margin: auto;
    left: 0;
    right: 0;
  }

  &--circle{
    width: 6rem;
    height: 6rem;
    border-radius: 100%;

    .material-icons, svg{
      margin-right: 0;
    }
  }

  &--circle-small{
    width: 3rem;
    height: 3rem;
    border-radius: 100%;

    .material-icons, svg{
      margin-right: 0;
    }
  }

  // color
  &:active{
    background: $color-border;
  }

  &--light{
    color: $color-grey;
    background: $color-light;
  }

  &--grey{
    color: $color-grey;
    background: $color-border;
  }

  &--primary{
    color: $color-light;
    background: $color-primary;

    &:active{
      background: darken($color-primary, 10%);
    }
  }

  &--primary-dark{
    color: $color-light;
    background: $color-primary-dark;

    &:active{
      background: darken($color-primary-dark, 10%);
    }
  }

  &--secondary{
    color: $color-light;
    background: $color-secondary;

    &:active{
      background: darken($color-secondary, 10%);
    }
  }

  &--dark{
    color: $color-light;
    background: $color-dark;

    &:active{
      background: darken($color-dark, 10%);
    }
  }

  &--danger{
    color: $color-light;
    background: $color-danger;

    &:active{
      background: darken($color-danger, 10%);
    }
  }

  &--transparent{
    background: transparent;
    color: $color-dark;
    padding: 0;

    &:active{
      background: transparent;
    }
  }

  &--transparent-primary{
    background: transparent;
    color: $color-primary;

    &:active{
      background: $color-border;
    }
  }

  &--transparent-danger{
    background: transparent;
    color: $color-danger;

    &:active{
      background: $color-border;
    }

    .spinner > .bounce1,.bounce2,.bounce3{
      background-color: $color-danger;
    }
  }

  //
  
  &:disabled{
    opacity: .3;
    cursor: not-allowed;
  }
}

a.button{
  color: $color-dark;
  outline: none;
}