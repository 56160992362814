@import '../../config/theme';

.wrapper{
  background-color: $color-light;

  > .spinner{
    position: absolute;
    left: 0;
    right: 0;
    margin: 50% auto 0 auto;

    > div{
      background: $color-secondary;
    }
  }
}